import Axios from "axios";
import React, { PureComponent, createContext } from "react";
import { mdiTransitConnectionHorizontal, mdiAccountAlert, mdiChatQuestion } from "@mdi/js";
import { isEmpty } from "lodash";
export const AppContext = createContext();

class AppProvider extends PureComponent {
    constructor(props) {
        console.log("AppProvider::constructor()");
        super(props);
        this.state = {
            settings: {
                app: {
                    accent_color: "#bd10e0"
                },
                charts: {
                    summary: {
                        type: "bar",
                        show_legend: false,
                        show_labels: false,
                        show_grid_lines: false,
                        show_angle_lines: false,
                        border_width: 4,
                        point_style: "circle",
                        point_border_width: 4,
                        point_radius: 4,
                        point_hover_radius: 6,
                        point_hover_border_width: 6,
                        line_tension: 0.3,
                        legend_font_color: "#7a7abf",
                        label_font_color: "#7a7abf",
                        grid_line_color: "#1C1F30",
                        angle_line_color: "#1C1F30",
                        tick_color: "#fff",
                        tick_width: 1,
                        tick_length: 8
                    },
                    detailed: {
                        type: "line",
                        show_legend: false,
                        show_labels: true,
                        show_grid_lines: true,
                        show_angle_lines: true,
                        border_width: 4,
                        point_style: "circle",
                        point_border_width: 4,
                        point_radius: 4,
                        point_hover_radius: 6,
                        point_hover_border_width: 6,
                        line_tension: 0.3,
                        legend_font_color: "#7a7abf",
                        label_font_color: "#7a7abf",
                        grid_line_color: "#1C1F30",
                        angle_line_color: "#1C1F30",
                        tick_color: "#fff",
                        tick_width: 1,
                        tick_length: 8
                    },
                    dashboard: {
                        type: "bar",
                        show_legend: true,
                        show_labels: true,
                        show_grid_lines: true,
                        show_angle_lines: true,
                        border_width: 4,
                        point_style: "circle",
                        point_border_width: 4,
                        point_radius: 4,
                        point_hover_radius: 6,
                        point_hover_border_width: 6,
                        line_tension: 0.3,
                        legend_font_color: "#7a7abf",
                        label_font_color: "#7a7abf",
                        grid_line_color: "#1C1F30",
                        angle_line_color: "#1C1F30",
                        tick_color: "#fff",
                        tick_width: 1,
                        tick_length: 8
                    },
                    monitor: {
                        type: "bar",
                        show_legend: false,
                        show_labels: true,
                        show_grid_lines: false,
                        show_angle_lines: true,
                        border_width: 4,
                        point_style: "circle",
                        point_border_width: 4,
                        point_radius: 4,
                        point_hover_radius: 6,
                        point_hover_border_width: 6,
                        line_tension: 0.3,
                        legend_font_color: "#7a7abf",
                        label_font_color: "#7a7abf",
                        grid_line_color: "#1C1F30",
                        angle_line_color: "#1C1F30",
                        tick_color: "#fff",
                        tick_width: 1,
                        tick_length: 8
                    }
                },
                tables: {
                    report: {
                        show_dps: true,
                        show_damage_all: true,
                        show_damage_shield: false,
                        show_damage_hull: false,
                        show_hits_all: true,
                        show_hits_shield: false,
                        show_hits_hull: false,
                        show_crit_chance: true,
                        show_kill: true,
                        show_critical: false,
                        show_miss: true,
                        show_duration: true,
                        show_accuracy: true,
                        show_shield_break: false,
                        show_immune: false,
                        show_flank: false,
                        show_dodge: false
                    }
                },
                keybinds: {
                    save_report: "CTRL+End",
                    open_solo_monitor: "CTRL+ALT+SHIFT+Home",
                    open_team_monitor: "CTRL+SHIFT+Home",
                    reset_log: "CTRL+Delete",
                    pause_parser: "CTRL+.",
                    passthrough_toggle: "CTRL+Insert"
                },
                monitors: {
                    team: {
                        opacity: false,
                        show_name: false,
                        show_handle: false,
                        show_full_name: true,
                        show_dps: true,
                        show_hps: false,
                        show_kills: false,
                        show_deaths: true,
                        show_damage_outgoing: true,
                        show_damage_incoming: false,
                        show_damage_self: false,
                        show_hardest_outgoing: true,
                        show_hardest_incoming: false,
                        show_hardest_self: false,
                        show_healing_outgoing: false,
                        show_healing_incoming: false,
                        show_healing_self: false,
                        show_duration_damage_outgoing: true,
                        show_duration_damage_incoming: false,
                        show_duration_damage_self: false,
                        show_duration_healing_outgoing: false,
                        show_duration_healing_incoming: false,
                        show_duration_healing_self: false
                    },
                    solo: {
                        opacity: 0.95,
                        fill: true,
                        flip: false,
                        show_dps: true,
                        show_hps: false,
                        show_kills: true,
                        show_deaths: false,
                        show_damage_outgoing: false,
                        show_damage_incoming: false,
                        show_damage_self: false,
                        show_hardest_outgoing: true,
                        show_hardest_incoming: false,
                        show_healing_self: false,
                        show_duration_damage_outgoing: true,
                        show_duration_damage_incoming: false,
                        show_duration_damage_self: false
                    }
                },
                notifications: {
                    news: true
                }
            },
            title: "",
            icon: ""
        };
        this.codes = {
            UPDATER: {
                CHECKING: 1,
                FOUND: 2,
                NOT_FOUND: 3
            },
            API: {
                SUCCESS: 200,
                ERROR: 500,
                DUPLICATE: 409,
                NOT_FOUND: 404,
                UNAUTHORIZED: 401,
                EXPIRED_TOKEN: 498,
                NO_SERVICE: 502,
                GATEWAY_TIMEOUT: 504,
                CONNECTION_TIMEOUT: 522
            }
        };

        // CDN Functions
        this.cdnGet = this.cdnGet.bind(this);

        // Page Title Functions
        this.pageTitleSet = this.pageTitleSet.bind(this);

        this.apiGet = this.apiGet.bind(this);
    }

    // API Context
    apiHandleErrors(_code, _data) {
        console.log("AppProvider::apiHandleErrors()");

        switch (_code) {
            case this.codes.API.UNAUTHORIZED: {
                console.log(_data);
                this.popupPush({
                    icon: mdiAccountAlert,
                    title: `Access Token Invalid`,
                    body: "Your access code is invalid. Please logout and log back in to resolve this issue.",
                    code: _code,
                    type: "error"
                });
                break;
            }
            case this.codes.API.EXPIRED_TOKEN: {
                this.popupPush({
                    icon: mdiAccountAlert,
                    title: `Access Token Expired`,
                    body: "Your access token has expired. Please restart the application to correct this issue.",
                    code: _code,
                    type: "error"
                });
                break;
            }
            case this.codes.API.NO_SERVICE: {
                this.popupPush({
                    icon: mdiTransitConnectionHorizontal,
                    title: `Service Interruption`,
                    body: "Unable to connect to STO-Nexus's API Server.",
                    code: _code,
                    type: "error"
                });
                break;
            }
            case this.codes.API.GATEWAY_TIMEOUT: {
                this.popupPush({
                    icon: mdiTransitConnectionHorizontal,
                    title: `Gateway Timed Out`,
                    body: "The request sent to STO-Nexus's API Server is taking too long. Please wait a few minutes and try again.",
                    code: _code,
                    type: "error"
                });
                break;
            }
            case this.codes.API.CONNECTION_TIMEOUT: {
                this.popupPush({
                    icon: mdiTransitConnectionHorizontal,
                    title: `Connection Timed Out`,
                    body: "The request sent to STO-Nexus's API Server is taking too long. Please wait a few minutes and try again.",
                    code: _code,
                    type: "error"
                });
                break;
            }
            default: {
                break;
            }
        }
    }

    apiGet(_path) {
        console.log(`AppProvider::apiGet(https://sto-nexus.com/api${_path})`);

        return new Promise((resolve, reject) => {
            const { user } = this.state;
            const client = Axios.create({
                baseURL: `https://sto-nexus.com/api`,
                timeout: 20000,
                headers: { Authorization: `Bearer ${user?.access_token}` }
            });
            client
                .get(_path)
                .then(resolve)
                .catch((error) => {
                    const { status, data } = error.response;
                    this.apiHandleErrors(status, data);
                    reject(error);
                });
        });
    }

    // CDN Context

    cdnGet(_path) {
        console.log(`AppProvider::cdnGet(https://sto-nexus.com/cdn${_path})`);

        return new Promise((resolve, reject) => {
            const { user } = this.state;
            const client = Axios.create({
                baseURL: `https://sto-nexus.com/cdn`,
                timeout: 20000,
                headers: { Authorization: `Bearer ${user?.access_token}` }
            });
            client
                .get(_path)
                .then(resolve)
                .catch((error) => {
                    const { status, data } = error.response;
                    this.apiHandleErrors(status, data);
                    reject(error);
                });
        });
    }

    configGet(_key) {
        console.log("AppProvider::configGet()");

        return this.state.settings[_key];
    }

    pageTitleSet(_title, _icon) {
        console.log("AppProvider::pageTitleSet()");
        this.setState({ title: _title, icon: _icon });
    }

    render() {
        console.log("AppProvider::render()");
        return (
            <AppContext.Provider
                value={{
                    API: {
                        get: this.apiGet,
                        statusCodes: this.codes.API
                    },
                    CDN: {
                        get: this.cdnGet
                    },
                    Config: {
                        settings: this.state.settings
                    },
                    PageTitle: {
                        set: this.pageTitleSet,
                        get: {
                            title: this.state.title,
                            icon: this.state.icon || mdiChatQuestion
                        }
                    },
                    codes: this.codes
                }}
            >
                {!isEmpty(this.state.settings) ? this.props.children : null}
            </AppContext.Provider>
        );
    }
}
export default AppProvider;
