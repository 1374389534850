import { Routes, Route, Outlet, BrowserRouter } from "react-router-dom";
import LandingPage from "./pages/Landing";
import SupportPage from "./pages/Support";
import ReportView from "./pages/Reports/view";
import DownloadPage from "./pages/Download/index";
import PaypalIndex from "./pages/Paypal";
import ScrollToTop from "./components/ScrollToTop";
import FaqsPage from "./pages/Faqs";
import { Component } from "react";
import MainMenu from "./components/MainMenu";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import { Container } from "react-bootstrap";
class AppRouter extends Component {
    render() {
        const AppLayout = () => {
            return (
                <>
                    <MainMenu dark />
                    <Container fluid>
                        <Outlet />
                    </Container>
                </>
            );
        };
        return (
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<AppLayout />}>
                            <Route index element={<LandingPage />} />
                            <Route path="support" element={<SupportPage />} />
                            <Route path="download" element={<DownloadPage />} />
                            <Route path="faqs" element={<FaqsPage />} />
                            <Route path="paypal/thank-you" element={<PaypalIndex />} />
                            <Route path="r/:id" element={<ReportView />} />
                            <Route path="forgot-password" element={<ForgotPassword />} />
                        </Route>
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default AppRouter;
