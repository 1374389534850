import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { AppContext } from "../contexts/AppContext";
class Photo extends Component {
    constructor(props) {
        console.log("Photo::constructor()");
        super(props);
        this.state = {
            image: "",
            loading: true
        };
    }

    componentDidMount() {
        console.log("Photo::componentDidMount()");
        const { model, index } = this.props;
        const { CDN, codes } = this.context;
        const URL = `/lookup/${model}/${index}`;
        CDN.get(URL)
            .then((res) => {
                const { url } = res.data;
                this.setState({
                    image: url,
                    loading: false
                });
            })
            .catch((err) => {
                const { status } = err.response;
                switch (status) {
                    case codes.API.ERROR:
                        break;
                    case codes.API.NOT_FOUND:
                        console.log(err.response.data);
                        break;
                    case codes.API.UNAUTHORIZED:
                        console.log(err.response.data);
                        break;
                    default:
                        break;
                }
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        const { size, width, height, className, revision, thumbnail = true, roundedCircle = true } = this.props;
        const { loading, image } = this.state;
        return loading ? <Icon path={mdiLoading} spin={1} size={size} /> : <Image src={`${image}?_v=${revision}`} width={width} height={height} className={className} thumbnail={thumbnail} roundedCircle={roundedCircle} />;
    }
}
Photo.contextType = AppContext;
export default Photo;
