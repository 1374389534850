import React, { Component } from "react";
import Feature from "./components/Feature";
import CustomizableInterface from "../../../../assets/customizable-interface.png";
import CharacterManagement from "../../../../assets/character-management.png";
import DetailedReports from "../../../../assets/detailed-reports.png";
import CharacterReports from "../../../../assets/character-reports.png";
import DPSMeters from "../../../../assets/dps-meters.png";
import Heading from "../../../../components/Heading";
class Features extends Component {
    render() {
        const features = [
            {
                image: DetailedReports,
                title: "Detailed DPS Reports",
                body: "Our Detailed DPS Reports provide you with a comprehensive analysis of your combat session in Star Trek Online. Our application parses combat log reports to calculate your damage output and generates detailed charts and tables that visualize your abilities. This means you can see which abilities are working well for you, which ones could use some improvement, and how your performance changes over time. With Detailed DPS Reports, you'll have the information you need to optimize your gameplay and achieve your goals."
            },
            {
                image: CharacterManagement,
                title: "Character Management",
                body: "Our application allows you to easily set portrait photos, gender, career, and species, so you can fully customize your character's appearance and identity. You can also track your character's progress over time and view detailed analytics on their performance, including damage output, healing, survivability, and more. With Character Management, you'll have the tools you need to create and manage characters that truly reflect your vision and playstyle."
            },
            {
                image: DPSMeters,
                title: "Real-Time DPS Monitoring",
                body: "With our DPS Monitoring Systems, you can track your damage output in Star Trek Online as it happens. Whether you're playing solo or as part of a team, our application parses combat log reports to calculate your DPS in real time. This means you can see how your performance stacks up against your goals and your teammates', allowing you to adjust your tactics and improve your gameplay on the fly. With Real-Time DPS Monitoring, you'll have the information you need to dominate the competition and achieve your goals."
            },
            {
                image: CustomizableInterface,
                title: "Customizable Interface",
                body: "Our Customizable Interface allows you to tailor our application to your unique needs and preferences. With our intuitive interface, you can easily customize everything from the color scheme to the charts you want to use and the columns that show in tables. You can even set your own keyboard shortcuts to streamline your workflow and make it easier to access the features you use most. With our Customizable Interface, you'll have the flexibility and control to create a setup that works best for you."
            },
            {
                image: CharacterReports,
                title: "Character DPS Breakdown",
                body: "This feature allows you to easily view reports and analytics related to specific characters on your account in Star Trek Online. With our application, you can see detailed information about your character's performance, including damage output, healing, survivability, and more. You can also track your progress over time and compare your performance to other players. With Character Management, you'll have the tools you need to fine-tune your gameplay and take your characters to the next level."
            }
        ];
        return (
            <section id="features">
                {features.map((feature, i) => {
                    const alt = i % 2 === 0 ? "true" : "false";
                    return (
                        <Feature key={i} image={feature.image} alt={alt}>
                            <Heading as="h2" className={`text-center text-md-${alt === "true" ? "end" : "start"}`}>
                                {feature.title}
                            </Heading>
                            <p className={`text-center text-md-${alt === "true" ? "end" : "start"}`}>{feature.body}</p>
                        </Feature>
                    );
                })}
            </section>
        );
    }
}

export default Features;
