import React from "react";
import AppHelper from "../helpers/AppHelper";
import Icon from "@mdi/react";
import { mdiFire, mdiMeteor } from "@mdi/js";

const TileCard = (props) => {
    const { title, weapon, buff, target, damage } = props;
    return (
        <div className={`tile-card ${props.reverse ? "reverse" : ""}`}>
            <div className="icon">
                <Icon path={mdiMeteor} size={2} />
            </div>
            <div className="left">
                <div className="title">{title}</div>
                <div className="weapon">{weapon}</div>
                <div className="buff">{buff}</div>
            </div>
            <div className="right">
                <div className="target">{target}</div>
                <div className="damage">
                    {AppHelper.toNumber(damage, 0)} <Icon path={mdiFire} size={1} />
                </div>
            </div>
        </div>
    );
};
export default TileCard;
