import React from 'react'
import BarChart from './Charts/BarChart'
import LineChart from './Charts/LineChart'
import RadarChart from './Charts/RadarChart'
import TimeSeries from './Charts/TimeSeries'

const ChartSwitch = (props) => {
    const settings = {
        app: { accent_color: '#9013fe' },
        chart: {
            summary: { type: 'bar', show_legend: false, show_labels: true, show_gridlines: true, show_anglelines: true, border_width: 4, point_style: 'circle', point_border_width: 4, point_radius: 4, point_hover_radius: 6, point_hover_border_width: 6, line_tension: 0.3, legend_font_color: '#7a7abf', label_font_color: '#7a7abf', gridlines_color: '#1C1F30', angleline_color: '#1C1F30' },
            detailed: { type: 'line', show_legend: false, show_labels: true, show_gridlines: true, show_anglelines: true, border_width: 4, point_style: 'circle', point_border_width: 4, point_radius: 4, point_hover_radius: 6, point_hover_border_width: 6, line_tension: 0.3, legend_font_color: '#7a7abf', label_font_color: '#7a7abf', gridlines_color: '#1C1F30', angleline_color: '#1C1F30' },
            dashboard: { type: 'bar', show_legend: false, show_labels: true, show_gridlines: true, show_anglelines: true, border_width: 4, point_style: 'circle', point_border_width: 4, point_radius: 4, point_hover_radius: 6, point_hover_border_width: 6, line_tension: 0.3, legend_font_color: '#7a7abf', label_font_color: '#7a7abf', gridlines_color: '#1C1F30', angleline_color: '#1C1F30' },
            monitor: { type: 'bar', show_legend: false, show_labels: false, show_gridlines: false, show_anglelines: true, border_width: 4, point_style: 'circle', point_border_width: 4, point_radius: 4, point_hover_radius: 6, point_hover_border_width: 6, line_tension: 0.3, legend_font_color: '#7a7abf', label_font_color: '#7a7abf', gridlines_color: '#1C1F30', angleline_color: '#1C1F30' }
        }
    }
    switch (props.type !== undefined ? props.type : settings.chart[props.component].type) {
        case 'radar': {
            return (
                <RadarChart
                    {...props}
                    data={{
                        labels: props.labels,
                        datasets: props.data
                    }}
                />
            )
        }
        case 'line': {
            return (
                <LineChart
                    {...props}
                    data={{
                        labels: props.labels,
                        datasets: props.data
                    }}
                />
            )
        }
        case 'time': {
            return <TimeSeries {...props} data={props.data} />
        }
        case 'bar': {
            return (
                <BarChart
                    {...props}
                    data={{
                        labels: props.labels,
                        datasets: props.data
                    }}
                />
            )
        }
        default: {
            return ''
        }
    }
}
export default ChartSwitch
