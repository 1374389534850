import { mdiDownload } from "@mdi/js/mdi";
import Icon from "@mdi/react";
import React, { Component } from "react";
import { Col, Container, Row, Button, Badge } from "react-bootstrap";
import DetailedReport from "../../assets/detailed-reports.png";
import FleetManager from "../../assets/fleet-manager.png";
import Axios from "axios";
import yaml from "js-yaml";

class DownloadPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parser: {
                latest: "",
                file: ""
            },
            manager: {
                latest: "",
                file: ""
            }
        };
        this.handleParserDownloadClick = this.handleParserDownloadClick.bind(this);
        this.handleManagerDownloadClick = this.handleManagerDownloadClick.bind(this);
    }

    componentDidMount() {
        Axios.get("https://sto-nexus.com/parser").then((res) => {
            let content = res.data;

            const jsonData = yaml.load(content);
            this.setState({
                parser: {
                    latest: jsonData.version,
                    file: `https://sto-nexus.com/parser/${jsonData.files[0].url}`
                }
            });
        });
        Axios.get("https://sto-nexus.com/manager").then((res) => {
            let content = res.data;

            const jsonData = yaml.load(content);
            this.setState({
                manager: {
                    latest: jsonData.version,
                    file: `https://sto-nexus.com/manager/${jsonData.files[0].url}`
                }
            });
        });
    }

    handleParserDownloadClick() {
        window.location.href = this.state.parser.file;
    }

    handleManagerDownloadClick() {
        window.location.href = this.state.manager.file;
    }

    render() {
        return (
            <>
                <div id="download">
                    <Container fluid>
                        <Row className="d-flex align-items-center mb-5">
                            <Col>
                                <div style={{ backgroundImage: `url(${DetailedReport})` }} className="image d-none d-lg-block"></div>
                            </Col>
                            <Col className="p-5">
                                <h1>
                                    <span>Nexus</span> Parser <Badge>v{this.state.parser.latest}</Badge>
                                </h1>
                                <p>
Our desktop client utilizes advanced features that necessitate elevated permissions, leading to a "Unknown Publisher" message upon launch in Windows. This occurs as we choose not to invest in a yearly code signing certificate for the sole purpose of displaying our name on the dialog box. Please be assured that our application is entirely safe to use and poses no threat to your computer. It strictly accesses logs within your Star Trek Online installation directory and does not engage in any form of spying or harmful activities. Your trust and security are our top priorities.</p>
                                <Row className="d-flex align-items-center justify-content-between mt-5">
                                    <Col>
                                        <Button variant="outline" onClick={this.handleParserDownloadClick} className="btn-white">
                                            <Icon path={mdiDownload} size={1} /> Download BETA
                                        </Button>
                                    </Col>
                                    <Col>
                                        <a href="https://www.virustotal.com/gui/file/811725fa1a910edd1d01f883428e64a4f363d14ed2711a5c23456d639f6cac06/details" className="ml-4" target="_blank" rel="noreferrer">
                                            VirusTotal Scan Results
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <div style={{ backgroundImage: `url(${FleetManager})` }} className="image d-none d-lg-block"></div>
                            </Col>
                            <Col className="p-5">
                                <h1>
                                    <span>Fleet</span> Manager <Badge>v{this.state.manager.latest}</Badge>
                                </h1>
                                <p>Discover the ultimate command center for your Star Trek Online fleet with our cutting-edge Fleet Manager Software. Effortlessly streamline fleet operations as you ascend to new heights of leadership. From seamless member management with the power to promote, kick, and demote at your fingertips, to a comprehensive recruitment history timeline – your fleet's evolution is at your command. Instantly gain insights into your fleet's composition, with real-time updates on Federation, Klingon, Romulan, and Dominion members. Elevate your strategic prowess and lead your fleet to victory with our intuitive and powerful Fleet Manager Software.</p>
                                <Row className="d-flex align-items-center justify-content-between mt-5">
                                    <Col>
                                        <Button variant="outline" onClick={this.handleManagerDownloadClick} className="btn-white">
                                            <Icon path={mdiDownload} size={1} /> Download BETA
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

DownloadPage.propTypes = {};

export default DownloadPage;
