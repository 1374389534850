import React, { useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import axios from "axios";
import ValidationHelper from "../../helpers/Validation";
import Icon from "@mdi/react";
import { mdiLoading, mdiSend } from "@mdi/js/mdi";

const SupportPage = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(false);
    const [validName, setValidName] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [validMessage, setValidMessage] = useState(false);
    const [success, setSuccess] = useState(false);
    const handleSubmit = (e) => {
        setProcessing(true);
        e.preventDefault();
        axios
            .post("/support/send", {
                name: name,
                email: email,
                message: message
            })
            .then((res) => {
                setName("");
                setEmail("");
                setMessage("");
                setSuccess(true);
                setProcessing(false);
            })
            .catch((err) => {
                setSuccess(false);
                setProcessing(false);
            });
    };
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        switch (name) {
            case "name": {
                setName(value);
                setValidName(ValidationHelper.isLength(value, { min: 3 }));
                break;
            }
            case "email": {
                setEmail(value);
                setValidEmail(ValidationHelper.isEmail(value));
                break;
            }
            case "message": {
                setMessage(value);
                setValidMessage(ValidationHelper.isLength(value, { min: 50 }));
                break;
            }
            default: {
                break;
            }
        }
    };
    return (
        <>
            <section id="support">
                <Container>
                    <Row>
                        {success ? (
                            <Col>
                                <h1>Your message has been sent!</h1>
                                <p>Expect 24 to 48 hours for a response</p>
                            </Col>
                        ) : (
                            <Col>
                                <h1>Support</h1>
                                <p>If you have any issues you can't resolve yourself, questions or concerns regarding our service or application or wish to simply leave a comment please feel free to contact us. The typical response time is between 24 and 48 hours.</p>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control placeholder="Your Name" name="name" onChange={handleChange} value={name} required />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control type="email" placeholder="Your Email" name="email" onChange={handleChange} value={email} required />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control as="textarea" placeholder="Your Message" name="message" onChange={handleChange} value={message} required />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <p className="text-left">
                                        {message.length < 50 ? (
                                            <>
                                                Required minimum message length: <strong>{message.length}</strong> / <strong>50 characters</strong>
                                            </>
                                        ) : (
                                            <>
                                                Message Length is <strong>Valid</strong>
                                            </>
                                        )}
                                    </p>
                                    <Form.Group>
                                        <Button type="submit" variant="outline" className="btn-white" disabled={processing || !validName || !validEmail || !validMessage}>
                                            {processing ? (
                                                <>
                                                    <Icon path={mdiLoading} spin /> Sending...
                                                </>
                                            ) : (
                                                <>
                                                    <Icon path={mdiSend} size={1} /> Send
                                                </>
                                            )}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default SupportPage;
