import { mdiAccountGroup, mdiClock } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import React, { Component } from "react";
import AppHelper from "../../../helpers/AppHelper";

class PlayerOverview extends Component {
    render() {
        const { report, image } = this.props;
        return (
            <div className="player-tab overview">
                <div className="gradient"></div>
                <div className="map" style={{ backgroundImage: `url(${image})` }}></div>
                <div className="content d-flex align-items-center">
                    <div className="details">
                        <div className="map-info">{`${AppHelper.try(report.difficulty, "name", "Unknown")} ${AppHelper.try(report.map, "zone", "Unknown")}`}</div>
                    </div>
                    <div className="level">
                        <Icon path={mdiAccountGroup} size={2} /> {report.group_size}
                    </div>
                </div>
                <div className="footer d-flex align-items-center">
                    <div className="handle">{moment(report.createdAt).format("MMM Do YYYY [at] hh:mm A")}</div>
                    <div className="dps">
                        {AppHelper.toHumanReadableTime(report.duration)}
                        <Icon path={mdiClock} size={1} />
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerOverview;
