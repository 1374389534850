import React from "react";
import PropTypes from "prop-types";
import DonutChart from "../../../components/Charts/DonutChart";
import { Card, Col, Row, Table } from "react-bootstrap";
import AppHelper from "../../../helpers/AppHelper";

const DamageTypes = (props) => {
    return props.types !== undefined ? (
        <Row>
            {Object.keys(props.types).map((_direction, i) => {
                return Object.keys(props.types[_direction]).length > 0 ? (
                    <Col key={i}>
                        <Card className="chart-table-card">
                            <Card.Header>{_direction} Damage Types</Card.Header>
                            <Card.Body>
                                <div className="chart-wrapper">
                                    <DonutChart
                                        width={150}
                                        height={150}
                                        responsive={true}
                                        maintainAspectRatio={false}
                                        data={{
                                            labels: Object.keys(props.types[_direction]),
                                            datasets: [
                                                {
                                                    label: "DPS",
                                                    cubicInterpolationMode: "monotone",
                                                    data: Object.values(props.types[_direction])
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                                <Table>
                                    <tbody>
                                        {Object.keys(props.types[_direction]).map((key, x) => {
                                            return (
                                                <tr key={x}>
                                                    <td align="left">{key.charAt(0).toUpperCase() + key.slice(1)}</td>
                                                    <td align="right">{AppHelper.toNumber(props.types[_direction][key], 0)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                ) : null;
            })}
        </Row>
    ) : null;
};

DamageTypes.propTypes = {
    types: PropTypes.object
};

export default DamageTypes;
