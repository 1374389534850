import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const DownloadBanner = (props) => {
    return (
        <div className='download-banner'>
            <Container>
                <Row>
                    <Col xs={12} md={9}>
                        <h2 className="text-center text-md-start">Start the experience now!</h2>
                        <p className="text-center text-md-start">By downloading your application you gain access to analytics nobody else has. Besides it's free!</p>
                    </Col>
                    <Col xs={12} md={1} className="text-center">
                        <Link to='/download' className='btn btn-download'>
                            Download Now
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


export default DownloadBanner
