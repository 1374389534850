import React, { Component } from "react";
import { AppContext } from "../contexts/AppContext";
import Photo from "./Photo";
import ToolTip from "./ToolTip";
export default class Avatar extends Component {
    componentDidMount() {
        console.log("Avatar::componentDidMount()");
    }
    render() {
        const { User } = this.context;
        return this.props?.showName !== undefined ? (
            <ToolTip text={User.get("name")}>
                <Photo className="avatar" {...this.props} />
            </ToolTip>
        ) : (
            <Photo className="avatar" {...this.props} />
        );
    }
}
Avatar.contextType = AppContext;
