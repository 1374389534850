import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const ToolTip = React.forwardRef(({ text, placement, ...props }, ref) => {
    return (
        <OverlayTrigger placement={placement || "auto"} overlay={<Tooltip ref={ref}>{text}</Tooltip>}>
            <span>{props.children}</span>
        </OverlayTrigger>
    );
});
export default ToolTip;
