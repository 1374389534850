import { mdiFire } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Component } from "react";
import Avatar from "../../../components/Avatar";
import AppHelper from "../../../helpers/AppHelper";

class PlayerTab extends Component {
    render() {
        const { player, active } = this.props;
        const { character } = player;
        return (
            <div className={active ? "player-tab active" : "player-tab"}>
                <div className="gradient"></div>
                <div className="content d-flex align-items-center">
                    <Avatar model="characters" index={character._id} revision={character.portrait} width={48} height={48} className={`avatar ${AppHelper.try(character.career, "name", "Unknown")}`} thumbnail roundedCircle />
                    <div className="details">
                        <div className="name">{character.name.split("@").shift()}</div>
                        <div className="faction">@{character.name.split("@").pop()} </div>
                    </div>
                    <div className="level">{character.level === 0 ? "" : character.level}</div>
                </div>
                <div className="footer d-flex align-items-center">
                    <div className="handle">{`${AppHelper.try(character.faction, "name", "")} ${AppHelper.try(character.career, "name", "")}`} Officer</div>
                    <div className="dps">
                        {AppHelper.toNumber(player.summary.dps.outgoing.all, 1)}
                        <Icon path={mdiFire} size={1} />
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerTab;
