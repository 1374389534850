import React, { Component } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import HeroImage from "../../assets/hero.png";
import HeroVector from "../../assets/hero-vector.svg";
import Heading from "../../../../components/Heading";
import stars from "../../assets/stars.jpg";
import video from "../../assets/smoke.mp4";
class Hero extends Component {
    componentDidMount() {
        const el = document.body.querySelector(".v-scroll");
        const parallax = el.querySelector(".background");
        const handleScroll = () => {
            const speed = 0.05;
            parallax.style.backgroundPositionY = `${el.scrollTop * speed}px`;
        };
        el.addEventListener("scroll", handleScroll);
        return () => {
            el.removeEventListener("scroll", handleScroll);
        };
    }
    render() {
        return (
            <section id="hero">
                <div className="background" style={{ backgroundImage: `url(${stars})` }}>
                    <video autoPlay loop muted>
                        <source src={video} />
                    </video>
                </div>
                <div className="overlay" style={{ backgroundImage: `url(${HeroVector})` }}></div>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={{ span: 8, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 1 }}>
                            <div className="content text-center text-md-start">
                                <Heading>Nexus Parser</Heading>
                                <p>Tired of clunky DPS parsers with outdated interfaces? Upgrade your gameplay experience with Nexus Parser. Our modern and intuitive interface provides real-time visualization of your DPS and in-depth analytics to track your character's progress. With fully customizable charts and easy-to-read tables, you'll have access to exclusive features that you need to succeed. Say goodbye to the past and embrace the future of analysis with Nexus Parser.</p>

                                <Link to="/download" className="btn btn-outline btn-white">
                                    Get it now!
                                </Link>
                            </div>
                        </Col>
                        <Col xs={{ span: 4, offset: 1 }} md={1} className="d-none d-md-flex">
                            <Image src={HeroImage} />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Hero;
