import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const FaqsPage = (props) => {
    return (
        <Container id="faqs">
            <Row>
                <Col>
                    <h2>Why are numbers so different compared to other parsers?</h2>
                    <p>This can be a complicated thing to grasp for some people so I'll try to break it down the best I can so everyone can understand whats going on here. First off let me say theres no right or wrong way to determine DPS to each their own. Our parser simply eliminates some of the issues some people face with other parsers.</p>
                    <p>
                        <strong>Other parsers</strong> are based on time in combat plus time out of combat. Out of combat time includes time between firing and time traveling to the next target.
                    </p>
                    <p>This means if you do 1 second of damage totaling 100dmg then do another 1 second of damage totaling 100dmg 10 seconds later your DPS would be 20dps</p>
                    <p>
                        <strong>Why?</strong> Because the dead time between shots is counted you're only doing 2 seconds of damage but the calculation is based of encounter duration (10 seconds) 200dmg / 10 seconds = 20dps
                    </p>
                    <p>
                        <strong>Our parser</strong> only counts time in combat with no dead space such as time between shots.
                    </p>
                    <p>This means if you do 1 second of damage totaling 100dmg then do another 1 second of damage totaling 100dmg 10 seconds later your DPS would be 100dps</p>
                    <p>
                        <strong>Why?</strong> Because the dead time between shots is NOT counted you're only doing 2 seconds of damage 200dmg / 2 seconds = 100dps
                    </p>

                    <h2>Why do it that way?</h2>
                    <p>Not everyone wants to rush through combat or be penalized for being a little slow on an attack. Our parser lets you relax because it doesn't matter how long it takes you to do something your results will be the same.</p>
                </Col>
            </Row>
        </Container>
    );
};
export default FaqsPage;
