import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ChartSwitch from "../../../components/ChartSwitch";
import DataTable from "../../../components/DataTable";
import { AppContext } from "../../../contexts/AppContext";
const Heals = (props) => {
    const { Config } = useContext(AppContext);
    const [heals, setHeals] = useState([]);
    const [chart, setChart] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        console.log(props.heals);
        let _heals = [];
        let _chart = {
            labels: [],
            datasets: [
                {
                    label: "HPS",
                    data: []
                },
                {
                    label: "HitPoints",
                    data: []
                },
                {
                    label: "Duration",
                    data: []
                }
            ]
        };
        props.heals.forEach((_heal) => {
            _chart.labels.push(_heal.name);
            _chart.datasets[0].data.push(_heal.hps.all);
            _chart.datasets[1].data.push(_heal.hitpoints.all);
            _chart.datasets[2].data.push(_heal.duration.all);

            let heal = {
                name: _heal.name,
                hps: _heal.hps.all,
                hitpoints: _heal.hitpoints.all,
                ticks: _heal.ticks.all,
                duration: _heal.duration.all
            };
            _heals.push(heal);
        });
        setChart(_chart);
        setHeals(_heals);
    }, [props.heals, Config]);

    const headers = [
        { key: "name", text: "Name" },
        { key: "hps", text: "HPS" },
        { key: "hitpoints", text: "HitPoints" },
        { key: "ticks", text: "Ticks" },
        { key: "duration", text: "Duration" }
    ];
    return (
        <>
            <Card>
                <Card.Header>{props.title}</Card.Header>
                <Card.Body style={{ height: 320 }}>
                    <ChartSwitch component="detailed" labels={chart.labels} data={chart.datasets} />
                </Card.Body>
            </Card>
            <Card>{heals.length > 0 ? <DataTable defaultKey="hps" limit={2} limits={[1, 3, 5, 10, 25, 50, 100]} thead={headers} tbody={heals} /> : null}</Card>
        </>
    );
};
export default Heals;
