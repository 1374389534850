import React from "react";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import AppProvider from "./contexts/AppContext";

import { createRoot } from "react-dom/client";
import "./scss/App.scss";
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <AppProvider>
        <Router />
    </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
