import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { AppContext } from "../../contexts/AppContext";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

class BarChart extends Component {
    constructor(props) {
        super(props);
        console.log("BarChart::constructor()");
    }

    componentDidMount() {
        console.log("BarChart::componentDidMount()");
    }
    condition = (_key) => {
        const { Config } = this.context;
        const { settings, component } = this.props;
        if (settings) {
            return settings[component][_key];
        }

        const _config = Config.settings.charts[component][_key];
        return _config;
    };
    render() {
        const { Config } = this.context;
        const { height, data } = this.props;
        const accent_color = Config.settings.app.accent_color;

        // Configure Bar Color Gradients
        const color = accent_color.substring(1, accent_color.length);
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        const len = data.datasets.length;
        let _maximum = 0;
        data.datasets.map((_data, i) => {
            const opacity = (len - i) / len;
            _data.backgroundColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
            _data.borderColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
            _data.data.forEach((_val) => {
                if (_val > _maximum) {
                    _maximum = _val;
                }
            });
            return _data;
        });
        const tickColor = this.condition("tick_color");
        const tickWidth = this.condition("tick_width");
        const tickLength = this.condition("tick_length");
        return (
            <Bar
                height={height !== undefined ? height : 300}
                data={data}
                options={{
                    drawBorder: false,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: this.condition("show_legend"),
                            labels: {
                                color: this.condition("legend_font_color")
                            }
                        },
                        tooltips: {
                            mode: "index",
                            backgroundColor: "#1B1B29",
                            titleAlign: "center",
                            titleFontSize: 14,
                            xPadding: 10,
                            titleFontFamily: "Quicksand",
                            titleMarginBottom: 10,
                            bodyFontFamily: "Lato",
                            bodyFontSize: 10,
                            position: "nearest",
                            yPadding: 10,
                            intersect: false, // Enable if you want labels to show when over a point
                            bodySpacing: 10,
                            displayColors: false,
                            callbacks: {
                                label: function (t, d) {
                                    return `${d.datasets[t.datasetIndex].label}: ${t.yLabel.toLocaleString("en-US", {
                                        maximumFractionDigits: 2
                                    })}`;
                                }
                            }
                        }
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 5,
                            bottom: 5
                        }
                    },
                    datasets: {
                        bar: {
                            drawBorder: false,
                            fill: true,
                            borderWidth: 0
                        }
                    },
                    scales: {
                        y: {
                            border: {
                                display: false
                            },
                            type: "logarithmic",
                            ticks: {
                                min: 0,
                                max: _maximum + 1,
                                display: false
                            },
                            grid: {
                                display: false
                            }
                        },
                        x: {
                            border: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                display: this.condition("show_labels"),
                                color: this.condition("label_font_color")
                            },
                            grid: {
                                display: this.condition("show_grid_lines"),
                                color: this.condition("grid_line_color"),
                                zeroLineColor: this.condition("grid_line_color"),
                                tickColor: tickColor,
                                tickWidth: tickWidth,
                                tickLength: tickLength,
                                offset: false
                            }
                        }
                    }
                }}
            />
        );
    }
}
BarChart.contextType = AppContext;

BarChart.propTypes = {
    height: PropTypes.number,
    data: PropTypes.object,
    settings: PropTypes.object,
    labels: PropTypes.array,
    show_grid_lines: PropTypes.bool,
    show_labels: PropTypes.bool,
    show_legend: PropTypes.bool,
    component: PropTypes.string
};
export default BarChart;
