import React, { Component } from "react";
import { Button, Container, Form } from "react-bootstrap";

class ForgotPassword extends Component {
    render() {
        return (
            <Container className="h-100 justify-content-center d-flex align-items-center">
                <Form>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>
                    <Button variant="success">Reset</Button>
                </Form>
            </Container>
        );
    }
}

export default ForgotPassword;
