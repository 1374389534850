class AppHelper {
    truncate(_str, _max) {
        const len = _str.length
        const longer = len > _max
        if (longer) {
            const newStr = _str.substring(0, _max) + (longer ? '...' : '')
            return <span title={_str}>{newStr}</span>
        }
        return _str
    }
    toNumber(_val, _dec, _opts) {
        return Number(_val ? (_dec === 0 ? Math.floor(_val) : _val) : 0).toLocaleString('en-US', { minimumFractionDigits: _dec, maximumFractionDigits: _dec }) + (_opts !== undefined && _opts.suffix !== undefined ? _opts.suffix : '')
    }
    toHumanReadableTime(d) {
        d = Number(d)
        var h = Math.floor(d / 3600)
        var m = Math.floor((d % 3600) / 60)
        var s = Math.floor((d % 3600) % 60)

        var hDisplay = h > 0 ? h + (h === 1 ? 'h ' : 'h ') : ''
        var mDisplay = m > 0 ? m + (m === 1 ? 'm ' : 'm ') : ''
        var sDisplay = s > 0 ? s + (s === 1 ? 's' : 's') : ''

        return hDisplay + mDisplay + sDisplay
    }
    toHumanReadableSize(_val, precision) {
        if (_val < 1024) {
            const size = this.toNumber(_val, precision)
            return `${this.toNumber(size)} B`
        } else if (_val < 1024 * 1024) {
            const size = this.toNumber(_val / 1024, precision)
            return `${this.toNumber(size)} KB`
        } else if (_val < 1024 * 1024 * 1024) {
            const size = this.toNumber(_val / 1024 / 1024, precision)
            return `${this.toNumber(size)} MB`
        } else if (_val < 1024 * 1024 * 1024 * 1024) {
            const size = this.toNumber(_val / 1024 / 1024 / 1024, precision)
            return `${this.toNumber(size)} GB`
        }
        return _val
    }

    pyramidSort(arr, func) {
        var newArr = []
        if (arr.length === 0) {
            return []
        }
        arr.sort(func)

        newArr.push(arr.pop())

        while (arr.length) {
            newArr[arr.length % 2 === 0 ? 'push' : 'unshift'](arr.pop())
        }

        return newArr
    }
    cdn(_type, _id) {
        return `https://cdn.sto-nexus.com/img/${_type}/${_id}.png`
    }
    try(_obj, _key, _default) {
        try {
            return _obj[_key] === undefined ? _default : _obj[_key]
        } catch (err) {
            return _default
        }
    }
}

export default new AppHelper()
