import React, { useEffect, useState } from "react";
import axios from "axios";
import MainMenu from "../../components/MainMenu";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiHeart } from "@mdi/js/mdi";

const PaypalIndex = (props) => {
    const [user, setUser] = useState({});
    const [donation, setDonation] = useState({});
    useEffect(() => {
        const search = props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        setDonation({ status: params.get("st"), amount: params.get("amt"), currency: params.get("cc") });

        axios
            .get(`https://sto-nexus.com/api/users/${params.get("cm")}`)
            .then((res) => {
                setUser(res.data);
            })
            .catch();
    }, [props.location.search]);
    return (
        <>
            <MainMenu dark />
            <section id="paypal" className="h-100">
                <Container className="h-100">
                    <Row className="h-100 d-flex justify-contents-center align-items-center">
                        <Col>
                            <Icon path={mdiHeart} style={{ color: "hotpink", width: 48, height: 48 }} className="fa-beat" />
                            <h2>Thank You</h2>
                            <p>
                                Your donation of{" "}
                                <strong>
                                    ${donation.amount} {donation.currency}
                                </strong>{" "}
                                means a lot to us. It shows us that you like what we've created and you wish to keep it going not just for yourself but for everyone who uses our service.
                            </p>
                            <p>From everyone who was involved in the making of STO-Nexus, you're amazing!</p>
                            <p>Thank you {user.name}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default PaypalIndex;
