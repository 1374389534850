import React from 'react'
import PropTypes from 'prop-types'

const PaginatorInfo = (props) => {
    const { start, end, count } = props
    return (
        <div className='paginator-info mr-auto'>
            Showing <span className='white'>{count === 0 ? 0 : start + 1}</span> to <span className='white'>{end > count ? count : end}</span> of <span className='white'>{count}</span> entries
        </div>
    )
}

PaginatorInfo.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    count: PropTypes.number
}

export default PaginatorInfo
