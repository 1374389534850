import React, { useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { withRouter } from "../components/withRouter";
import { NavLink } from "react-router-dom";
import logo from "../logo.svg";

const MainMenu = (props) => {
    const collapseRef = useRef();
    return (
        <Navbar bg="navbar-dark" expand="lg" fixed={props.fixed}>
            <Container fluid>
                <Navbar.Brand href="/">
                    <img src={logo} alt="Nexus Parser" /> BETA
                </Navbar.Brand>

                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onBlur={(e) => {
                        if (!e.target.classList.contains("collapsed")) {
                            e.target.click();
                        }
                    }}
                />
                <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav" ref={collapseRef}>
                    <Nav>
                        <Nav.Link as={NavLink} to="/">
                            Home
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/support">
                            Support
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/faqs">
                            FAQs
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/download">
                            <span className="btn btn-outline btn-white">Download</span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default withRouter(MainMenu);
