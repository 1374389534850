import React, { useState } from "react";
import { Dropdown, Form, Pagination, Table } from "react-bootstrap";
import AppHelper from "../helpers/AppHelper";
import PaginatorInfo from "./Paginator/PaginatorInfo";
import moment from "moment";
import { mdiChevronDown, mdiChevronUp, mdiUnfoldMoreHorizontal } from "@mdi/js";
import Icon from "@mdi/react";
const DataTable = (props) => {
    const [orderBy, setOrderBy] = useState({ field: props.defaultKey, direction: "desc", type: "number" });
    const [limit, setLimit] = useState(props.limit);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const last = page * limit;
    const first = last - limit;
    const children =
        props.simple === undefined
            ? props.tbody.filter((_child) => {
                  return JSON.stringify(Object.values(_child)).toLowerCase().indexOf(query) > -1;
              })
            : props.tbody;
    const items = props.simple === undefined ? children.slice(first, last) : children;
    const pageNumbers = props.simple === undefined ? [...Array(Math.ceil(children.length / limit)).keys()] : [];

    const handleLimitChange = (e) => {
        setLimit(Number(e.target.dataset.key));
        setPage(1);
    };
    const handleQueryChange = (e) => {
        setQuery(e.target.value.toLowerCase());
        setPage(1);
    };
    const handleNextClick = () => {
        setPage(page + 1);
    };
    const handlePrevClick = () => {
        setPage(page - 1);
    };
    const handlePageClick = (e) => {
        const _page = Number(e.target.innerText);
        if (!isNaN(_page)) {
            setPage(_page);
        }
    };
    const sort = (field) => {
        let direction = "desc";
        if (field === orderBy.field) {
            direction = orderBy.direction === "asc" ? "desc" : "asc";
        }
        const type = isNaN(parseInt(field)) ? "string" : "number";
        setOrderBy({ field: field, direction: direction, type: type });
    };
    return (
        <section className="datatable">
            <div className="paginator">
                {props.simple === undefined ? (
                    <div className="col paginator-header d-flex align-items-center mb-3">
                        <Form inline="true">
                            <Form.Group>
                                <Form.Label>Display</Form.Label>

                                <Dropdown>
                                    <Dropdown.Toggle variant="transparent">{limit}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {props.limits.map((_limit) => {
                                            return (
                                                <Dropdown.Item key={_limit} data-key={_limit} onClick={handleLimitChange}>
                                                    {_limit}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Form>
                        <div className="paginator-info ml-3 mr-auto">
                            <PaginatorInfo start={first} end={last} count={children.length} />
                        </div>
                        {props.sortFields !== undefined ? (
                            <Form inline="true">
                                <Form.Group>
                                    <Form.Label>Sort By</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="transparent">{props.sortFields[props.defaultSort]}</Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {Object.keys(props.sortFields).map((_key) => {
                                                return (
                                                    <Dropdown.Item key={_key} data-key={_key} onClick={props.sortHandler}>
                                                        {props.sortFields[_key]}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                            </Form>
                        ) : null}
                        <Form inline="true" className="ms-auto">
                            <Form.Group>
                                <Form.Control onChange={handleQueryChange} placeholder="Search..." />
                            </Form.Group>
                        </Form>
                    </div>
                ) : null}
                <div className="col paginator-content">
                    <Table responsive={props.simple === undefined}>
                        <thead>
                            <tr>
                                {props.thead
                                    .filter((_el) => _el.show === undefined || _el.show === true)
                                    .map((_header, i) => {
                                        return (
                                            <th key={`head-${i}`} onClick={() => sort(_header.key)}>
                                                <span>{_header.text}</span> <Icon className={_header.key === orderBy.field ? orderBy.direction : "unsorted"} path={_header.key === orderBy.field ? (orderBy.direction === "desc" ? mdiChevronUp : mdiChevronDown) : mdiUnfoldMoreHorizontal} width={16} />
                                            </th>
                                        );
                                    })}
                            </tr>
                        </thead>
                        {items.length > 0 ? (
                            <tbody className="borderless">
                                {items
                                    .sort((a, b) => {
                                        if (orderBy.type === "string") {
                                            if (a[orderBy.field] === undefined) {
                                                a[orderBy.field] = "";
                                            }
                                            if (b[orderBy.field] === undefined) {
                                                b[orderBy.field] = "";
                                            }
                                            if (orderBy.direction === "asc") {
                                                if (a[orderBy.field] < b[orderBy.field]) {
                                                    return -1;
                                                }
                                                if (b[orderBy.field] < a[orderBy.field]) {
                                                    return 1;
                                                }
                                                return 0;
                                            } else if (orderBy.direction === "desc") {
                                                if (a[orderBy.field] > b[orderBy.field]) {
                                                    return -1;
                                                }
                                                if (b[orderBy.field] > a[orderBy.field]) {
                                                    return 1;
                                                }
                                                return 0;
                                            }
                                        } else {
                                            if (orderBy.direction === "desc") {
                                                return b[orderBy.field] - a[orderBy.field];
                                            } else if (orderBy.direction === "asc") {
                                                return a[orderBy.field] - b[orderBy.field];
                                            }
                                            return 0;
                                        }
                                        return 0;
                                    })
                                    .map((_row, i) => {
                                        return (
                                            <tr key={`data-${i}`} className={_row.highlight ? "highlight" : null}>
                                                {props.thead
                                                    .filter((_el) => _el.show === undefined || _el.show === true)
                                                    .map((_header, x) => {
                                                        const text = _row[_header.key] === undefined ? "--" : isNaN(parseInt(_row[_header.key])) ? _row[_header.key] : AppHelper.toNumber(_row[_header.key], 0);

                                                        if (_header.key.indexOf("duration") > -1) {
                                                            return (
                                                                <td key={x} data-value={_row[_header.key]}>
                                                                    {AppHelper.toHumanReadableTime(_row[_header.key])}
                                                                </td>
                                                            );
                                                        } else if (_header.key.indexOf("time") > -1) {
                                                            return (
                                                                <td key={x} data-value={_row[_header.key]}>
                                                                    {moment(_row[_header.key]).fromNow()}
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <td key={x} data-value={text}>
                                                                    {text}
                                                                </td>
                                                            );
                                                        }
                                                    })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        ) : null}
                    </Table>
                </div>
                {props.simple === undefined ? (
                    <div className="col paginator-footer mt-3 d-flex align-items-center justify-content-between mb-3">
                        <PaginatorInfo start={first} end={last} count={children.length} />
                        <Pagination className="mb-0">
                            {page > 1 ? <Pagination.Prev onClick={handlePrevClick} /> : null}
                            {pageNumbers.map((_page) => {
                                _page = _page + 1;
                                return (
                                    <Pagination.Item key={_page} active={_page === page} onClick={handlePageClick}>
                                        {_page}
                                    </Pagination.Item>
                                );
                            })}
                            {page !== pageNumbers.pop() + 1 ? <Pagination.Next onClick={handleNextClick} /> : null}
                        </Pagination>
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default DataTable;
