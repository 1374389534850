import React, { Component } from "react";
import Hero from "./sections/hero/Hero";
import DownloadBanner from "./components/DownloadBanner";
import Statistics from "./sections/statistics/Statistics";
import Features from "./sections/features/Features";
import Footer from "./components/Footer";
import socketIOClient from "socket.io-client";

class LandingPage extends Component {
    render() {
        const socket = socketIOClient("https://ws.sto-nexus.com", { autoConnect: true });
        socket.on("active-clients", (num) => {
            console.log("Active Clients:", num);
        });
        return (
            <div id="landing-page">
                <div className="v-scroll">
                    <Hero />
                    <Features />
                    <Statistics />
                    <DownloadBanner />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default LandingPage;
