import React, { Component } from "react";
import PropTypes from "prop-types";
import ChartCard from "../../../components/ChartCard";
import TileCard from "../../../components/TileCard";
import { Col, Row } from "react-bootstrap";
import DamageTypes from "./DamageTypes";
import AppHelper from "../../../helpers/AppHelper";
import { mdiCallMissed, mdiDice5, mdiFire, mdiHeartPulse, mdiMeteor, mdiRadioactiveCircleOutline, mdiSkull, mdiSpider, mdiTargetAccount } from "@mdi/js";
class Dashboard extends Component {
    constructor(props) {
        console.log("Dashboard::constructor()");
        super(props);
    }
    render() {
        console.log("Dashboard::render()");
        const { player, report } = this.props;

        const top_dps = Math.max.apply(
            Math,
            report.players.map((_player) => {
                return _player.summary.dps?.outgoing?.all || 0;
            })
        );
        const top_hps = Math.max.apply(
            Math,
            report.players.map((_player) => {
                return _player.summary.hps?.self?.all || 0;
            })
        );
        const top_kills = Math.max.apply(
            Math,
            report.players.map((_player) => {
                return _player.procs?.outgoing?.kill || 0;
            })
        );
        const top_critical_attacks = Math.max.apply(
            Math,
            report.players.map((_player) => {
                return _player.procs?.outgoing?.critical || 0;
            })
        );
        const top_accuracy = Math.max.apply(
            Math,
            report.players.map((_player) => {
                return _player.accuracy;
            })
        );

        return (
            <div className="overview">
                <Row>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Top DPS", "Your DPS"],
                                datasets: [
                                    {
                                        data: [top_dps, top_dps - player.summary.dps.outgoing.all]
                                    }
                                ]
                            }}
                            header="DPS"
                            body={AppHelper.toNumber(player.summary.dps.outgoing.all, 0)}
                            icon={mdiFire}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Top HPS", "Your HPS"],
                                datasets: [
                                    {
                                        data: [top_hps, top_hps - player.summary.hps.self.all]
                                    }
                                ]
                            }}
                            header="HPS"
                            body={AppHelper.toNumber(player.summary.hps.self.all, 0)}
                            icon={mdiHeartPulse}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Hull", "Shield"],
                                datasets: [
                                    {
                                        data: [player.summary.damage.outgoing.hull, player.summary.damage.outgoing.shield]
                                    }
                                ]
                            }}
                            header="Hull vs Shield"
                            icon={mdiMeteor}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Hull", "Shield"],
                                datasets: [
                                    {
                                        data: [player.summary.heals.self.hull, player.summary.heals.self.shield]
                                    }
                                ]
                            }}
                            header="Hull vs Shield"
                            icon={mdiHeartPulse}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Top Kills", "Your Kills"],
                                datasets: [
                                    {
                                        data: [top_kills, top_kills - (AppHelper.try(player, "procs", undefined) === undefined || AppHelper.try(player.procs, "outgoing", undefined) === undefined ? 0 : AppHelper.try(player.procs?.outgoing, "kill", 0))]
                                    }
                                ]
                            }}
                            header="Enemy Kills"
                            body={AppHelper.try(player, "procs", undefined) === undefined || AppHelper.try(player.procs, "outgoing", undefined) === undefined ? 0 : AppHelper.toNumber(AppHelper.try(player.procs?.outgoing, "kill", 0), 0)}
                            icon={mdiSkull}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Top Missed Attacks", "Your Kills"],
                                datasets: [
                                    {
                                        data: [top_kills, top_kills - AppHelper.try(player, "procs", undefined) === undefined || AppHelper.try(player.procs, "outgoing", undefined) === undefined ? 0 : AppHelper.try(player.procs?.outgoing, "kill", 0)]
                                    }
                                ]
                            }}
                            header="Missed Attacks"
                            body={AppHelper.try(player, "procs", undefined) === undefined || AppHelper.try(player.procs, "outgoing", undefined) === undefined ? 0 : AppHelper.toNumber(AppHelper.try(player.procs?.outgoing, "miss", 0), 0)}
                            icon={mdiCallMissed}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Top Critical Attacks", "Your Critical Attacks"],
                                datasets: [
                                    {
                                        data: [top_critical_attacks, top_critical_attacks - AppHelper.try(player, "procs", undefined) === undefined || AppHelper.try(player.procs, "outgoing", undefined) === undefined ? 0 : AppHelper.try(player.procs?.outgoing, "critical", 0)]
                                    }
                                ]
                            }}
                            header="Critical Attacks"
                            body={AppHelper.try(player, "procs", undefined) === undefined || AppHelper.try(player.procs, "outgoing", undefined) === undefined ? 0 : AppHelper.toNumber(AppHelper.try(player.procs?.outgoing, "critical", 0), 0)}
                            icon={mdiRadioactiveCircleOutline}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Hull", "Shield"],
                                datasets: [
                                    {
                                        data: [player.crit_chance, 100 - player.crit_chance]
                                    }
                                ]
                            }}
                            header="Dominance"
                            tooltip="Critical Chance"
                            body={AppHelper.toNumber(player.crit_chance, 0, { suffix: "%" })}
                            icon={mdiSpider}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Hull", "Shield"],
                                datasets: [
                                    {
                                        data: [player.crit_chance, 100 - player.crit_chance]
                                    }
                                ]
                            }}
                            header="Critical Chance"
                            body={AppHelper.toNumber(player.crit_chance, 0, { suffix: "%" })}
                            icon={mdiDice5}
                        />
                    </Col>
                    <Col>
                        <ChartCard
                            data={{
                                labels: ["Top Accuracy", "Your Accuracy"],
                                datasets: [
                                    {
                                        data: [top_accuracy, top_accuracy - player.accuracy]
                                    }
                                ]
                            }}
                            header="Accuracy"
                            body={AppHelper.toNumber(player.accuracy, 0, { suffix: "%" })}
                            icon={mdiTargetAccount}
                        />
                    </Col>
                </Row>
                {player.hardest !== undefined ? (
                    <Row>
                        {player.hardest !== undefined && player.hardest.outgoing !== undefined ? (
                            <Col xs={6}>
                                <TileCard title="Hardest Outgoing Attack" damage={player.hardest.outgoing.damage} target={player.hardest.outgoing.target} buff={player.hardest.outgoing.buff} weapon={player.hardest.outgoing.name} />
                            </Col>
                        ) : null}
                        {player.hardest !== undefined && player.hardest.incoming !== undefined ? (
                            <Col xs={6}>
                                <TileCard reverse title="Hardest Incoming Attack" damage={player.hardest.incoming.damage} target={player.hardest.incoming.target} buff={player.hardest.incoming.buff} weapon={player.hardest.incoming.name} />
                            </Col>
                        ) : null}
                    </Row>
                ) : null}
                {player.summary.damage_types !== undefined ? (
                    <Row>
                        <Col>
                            <DamageTypes types={player.summary.damage_types} />
                        </Col>
                    </Row>
                ) : null}
            </div>
        );
    }
}

Dashboard.propTypes = {
    player: PropTypes.object
};

export default Dashboard;
