import React, { Component } from "react";
import Loading from "../../../../../components/Loading";
import AppHelper from "../../../../../helpers/AppHelper";
import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";

class Statistic extends Component {
    render() {
        const { data } = this.props;
        return (
            <div className="statistic-container">
                {!data || data.length === 0 || data === undefined ? (
                    <Loading />
                ) : (
                    <ListGroup>
                        {data.map((item, i) => {
                            return (
                                <ListGroupItem key={i}>
                                    <span>{item.name}</span> <Badge>{AppHelper.toNumber(this.props.hasOwnProperty("dmg") ? item.damage : item.dps, 0)}</Badge>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                )}
            </div>
        );
    }
}

export default Statistic;
