import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Attacks from "./Attacks";
import Heals from "./Heals";
import Loading from "../../../components/Loading";
import Dashboard from "./Dashboard";
import { AppContext } from "../../../contexts/AppContext";
import { mdiArrowLeftBottom, mdiArrowRightTop, mdiArrowUDownLeft, mdiEye, mdiFire, mdiHeart, mdiHexagon, mdiHexagonMultiple } from "@mdi/js";
import Icon from "@mdi/react";
import PlayerOverview from "./PlayerOverview";
import PlayerTab from "./PlayerTab";
import { isEmpty } from "lodash";

class Players extends Component {
    constructor(props) {
        console.log("Players::constructor()");
        super(props);
        this.state = {
            tab: "overview",
            heals: [],
            image: "",
            attacks: [],
            category: "attacks",
            player: this.props.players[0],
            direction: "incoming"
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
    }

    componentDidMount() {
        console.log("Players::componentDidMount()");
        const { CDN, codes } = this.context;
        CDN.get(`/lookup/maps/${this.props.report.map._id}`)
            .then((res) => {
                const { url } = res.data;
                this.setState({
                    image: url
                });
            })
            .catch((err) => {
                const { status } = err.response;
                switch (status) {
                    case codes.API.ERROR:
                        break;
                    case codes.API.NOT_FOUND:
                        console.log(err.response.data);
                        break;
                    case codes.API.UNAUTHORIZED:
                        console.log(err.response.data);
                        break;
                    default:
                        break;
                }
                this.setState({
                    loading: false
                });
            });
    }
    handlePlayerChange(_player) {
        this.setState({
            player: _player,
            tab: "overview",
            attacks: _player.attacks,
            heals: _player.heals
        });
    }
    handleTabChange(_key) {
        const { player } = this.state;
        switch (_key) {
            case "attacks": {
                if (!isEmpty(player.attacks.outgoing)) {
                    this.setState({
                        category: "attacks",
                        direction: "outgoing",
                        attacks: player.attacks.outgoing,
                        heals: [],
                        tab: _key
                    });
                } else if (!isEmpty(player.attacks.incoming)) {
                    this.setState({
                        category: "attacks",
                        direction: "incoming",
                        attacks: player.attacks.incoming,
                        heals: [],
                        tab: _key
                    });
                } else if (!isEmpty(player.attacks.self)) {
                    this.setState({
                        category: "attacks",
                        direction: "self",
                        attacks: player.attacks.self,
                        heals: [],
                        tab: _key
                    });
                }
                break;
            }
            case "heals": {
                if (!isEmpty(player.heals.self)) {
                    this.setState({
                        category: "heals",
                        direction: "self",
                        heals: player.heals.self,
                        attacks: [],
                        tab: _key
                    });
                } else if (!isEmpty(player.heals.outgoing)) {
                    this.setState({
                        category: "heals",
                        direction: "outgoing",
                        heals: player.heals.outgoing,
                        attacks: [],
                        tab: _key
                    });
                } else if (!isEmpty(player.heals.incoming)) {
                    this.setState({
                        category: "heals",
                        direction: "incoming",
                        heals: player.heals.incoming,
                        attacks: [],
                        tab: _key
                    });
                }
                break;
            }
            case "pets": {
                this.setState({
                    heals: [],
                    attacks: [],
                    tab: _key
                });
                break;
            }
            default:
                this.setState({
                    tab: _key
                });
                break;
        }
    }
    handleSubTabChange(_key) {
        const { player, category } = this.state;
        this.setState({
            direction: _key
        });
        switch (category) {
            case "heals": {
                this.setState({
                    heals: player.heals[_key]
                });
                break;
            }
            case "attacks": {
                this.setState({
                    attacks: player.attacks[_key]
                });
                break;
            }
            default: {
                break;
            }
        }
    }
    render() {
        console.log("Players::render()");
        const { players, report, orientation } = this.props;
        const { player, category, tab, image } = this.state;
        const isVertical = orientation === undefined || orientation === "vertical";
        return isEmpty(player) ? (
            <Loading />
        ) : (
            <Row className="h-100">
                {isVertical ? (
                    <Col className="h-100" xs={3} sm={2} md={2} lg={1} xl={3}>
                        <Row className="h-100">
                            <Col style={{ padding: 0 }}>
                                <div className="v-scroll h-100">
                                    <Nav variant="pills" className="flex-column" style={{ paddingLeft: 5, paddingRight: 5, overflowY: "auto", overflowX: "hidden" }}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="overview">
                                                <PlayerOverview report={report} image={image} />
                                            </Nav.Link>
                                        </Nav.Item>
                                        {players.map((_player, i) => {
                                            return (
                                                <Nav.Item key={_player._id}>
                                                    <Nav.Link eventKey={_player._id} onClick={() => this.handlePlayerChange(_player)}>
                                                        <PlayerTab player={_player} active={_player.character._id === player.character._id} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            );
                                        })}
                                    </Nav>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
                <Col xs={isVertical ? 9 : 12} sm={isVertical ? 10 : 12} md={isVertical ? 10 : 12} lg={isVertical ? 11 : 12} xl={isVertical ? 9 : 12} className="h-100" style={{ overflow: "auto" }}>
                    {!isVertical ? (
                        <Nav variant="pills horizontal" className="mb-3">
                            {players.map((_player, i) => {
                                return (
                                    <Nav.Item key={i}>
                                        <Nav.Link className={_player._id === player._id ? "active" : ""} eventKey={_player.id} onClick={() => this.handlePlayerChange(_player)}>
                                            <Icon path={mdiHexagon} size={1} /> {_player.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                        </Nav>
                    ) : null}
                    <Row>
                        <Col xs={12}>
                            <Tab.Container activeKey={tab} onSelect={this.handleTabChange}>
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="overview">
                                            <Icon path={mdiEye} size={1} /> Overview
                                        </Nav.Link>
                                        <Nav.Link eventKey="attacks">
                                            <Icon path={mdiFire} size={1} /> Attacks
                                        </Nav.Link>
                                        <Nav.Link eventKey="heals" disabled={isEmpty(player.heals.incoming) && isEmpty(player.heals.outgoing) && isEmpty(player.heals.self)}>
                                            <Icon path={mdiHeart} size={1} /> Heals
                                        </Nav.Link>
                                        {orientation === undefined ? (
                                            <Nav.Link disabled={isEmpty(player.pets)} eventKey="pets">
                                                <Icon path={mdiHexagonMultiple} size={1} /> Pets
                                            </Nav.Link>
                                        ) : null}
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="overview">{tab === "overview" ? <Dashboard player={player} report={report} /> : null}</Tab.Pane>
                                    <Tab.Pane eventKey="pets">{!isEmpty(player.pets) ? <Players players={player.pets} report={report} orientation="horizontal" /> : null}</Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            {tab !== "overview" && tab !== "pets" ? (
                                <Tab.Container activeKey={this.state.direction} onSelect={this.handleSubTabChange}>
                                    <Nav variant="tabs accent">
                                        <Nav.Item>
                                            <Nav.Link disabled={isEmpty(player[category].incoming)} eventKey="incoming">
                                                <Icon path={mdiArrowLeftBottom} size={1} /> Incoming
                                            </Nav.Link>
                                            <Nav.Link disabled={isEmpty(player[category].outgoing)} eventKey="outgoing">
                                                <Icon path={mdiArrowRightTop} size={1} /> Outgoing
                                            </Nav.Link>
                                            <Nav.Link disabled={isEmpty(player[category].self)} eventKey="self">
                                                <Icon path={mdiArrowUDownLeft} size={1} /> Self
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={this.state.direction}>
                                            {category === "attacks" ? <Attacks weapons={this.state.attacks} /> : null}
                                            {category === "heals" ? <Heals heals={this.state.heals} /> : null}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
Players.contextType = AppContext;
Players.propTypes = {
    players: PropTypes.array
};

export default Players;
