import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import Statistic from "./components/Statistic";

class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statistics: []
        };
    }

    componentDidMount() {
        axios
            .get("https://sto-nexus.com/api/statistics")
            .then((res) => {
                this.setState({
                    statistics: res.data
                });
            })
            .catch((err) => console.error(err));
    }

    render() {
        const { statistics } = this.state;

        return (
            <Container fluid id="statistics">
                <Row>
                    <Col lg={4}>
                        <div className="statistic">
                            <h2>DPS Leaderboard</h2>
                            <Statistic data={statistics.players} />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="statistic">
                            <h2>Strongest Weapons</h2>
                            <Statistic data={statistics.weapons} dmg />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="statistic">
                            <h2>Highest DPS Maps</h2>
                            <Statistic data={statistics.maps} />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

Statistics.propTypes = {};

export default Statistics;
