import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import "chartjs-adapter-date-fns";
import { AppContext } from "../../contexts/AppContext";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

// Disable animating charts by default.
const TimeSeries = (props) => {
    const { Config } = useContext(AppContext);
    const [options, setOptions] = useState({});
    const [datasets, setDatasets] = useState([]);
    useEffect(() => {
        let data = [];
        Object.keys(props.data).forEach((key) => {
            data.push({
                x: new Date(key).valueOf(),
                y: props.data[key]
            });
        });
        setDatasets(
            data.sort((a, b) => {
                if (a.t < b.t) return -1;
                if (a.t > b.t) return 1;
                return 0;
            })
        );
        setOptions({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                tooltips: {
                    intersect: false,
                    mode: "index",
                    callbacks: {
                        label: function (tooltipItem, myData) {
                            return parseFloat(tooltipItem.value).toFixed();
                        }
                    }
                }
            },
            scales: {
                x: {
                    type: "time",
                    distribution: "series",
                    offset: true,
                    ticks: {
                        major: {
                            enabled: true,
                            fontStyle: "bold"
                        },
                        source: "data",
                        autoSkip: true,
                        autoSkipPadding: 75,
                        maxRotation: 0
                    }
                },
                y: {
                    ticks: {
                        min: 0,
                        display: false
                    },
                    grid_lines: {
                        display: false
                    },
                    scaleLabel: {
                        display: false
                    }
                }
            }
        });
    }, [props.data]);
    return (
        <Line
            height={props.height !== undefined ? props.height : 300}
            data={{
                datasets: [
                    {
                        backgroundColor: Config.get("app").accent_color,
                        borderColor: Config.get("app").accent_color,
                        data: datasets,
                        type: "bar",
                        pointRadius: 0,
                        fill: false,
                        lineTension: 0,
                        borderWidth: 2
                    }
                ]
            }}
            options={options}
        />
    );
};
TimeSeries.propTypes = {
    height: PropTypes.number,
    data: PropTypes.object,
    labels: PropTypes.array,
    show_grid_lines: PropTypes.bool,
    show_labels: PropTypes.bool,
    show_legend: PropTypes.bool,
    component: PropTypes.string,
    border_width: PropTypes.number,
    point_border_width: PropTypes.number,
    point_radius: PropTypes.number,
    point_hover_radius: PropTypes.number,
    point_hover_border_width: PropTypes.number,
    line_tension: PropTypes.number
};
export default TimeSeries;
