import React, { PureComponent } from "react";
import { Radar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { AppContext } from "../../contexts/AppContext";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
// Disable animating charts by default.
class RadarChart extends PureComponent {
    constructor(props) {
        console.log("RadarChart::constructor()");
        super(props);
    }

    condition = (_key) => {
        const { Config } = this.context;
        const { settings, component } = this.props;
        if (settings) {
            return settings[component][_key];
        }
        const _config = Config.settings.charts[component][_key];
        return _config;
    };
    render() {
        console.log("RadarChart::render()");
        const { Config } = this.context;

        const showLabels = this.condition("show_labels");
        const showLegend = this.condition("show_legend");
        // const showGridLines = this.condition("show_labels"); this.props.show_grid_lines !== undefined ? this.props.show_grid_lines : Config.get("chart")[this.props.component].show_grid_lines;
        const showAngleLines = this.condition("show_angle_lines");
        const lineTension = this.condition("line_tension");
        const borderWidth = this.condition("border_width");
        const pointBorderWidth = this.condition("point_border_width");
        const pointRadius = this.condition("point_radius");
        const pointHoverBorderWidth = this.condition("point_hover_border_width");
        const pointHoverRadius = this.condition("point_hover_radius");
        const showGridLines = this.condition("show_grid_lines");
        const gridLineColor = this.condition("grid_line_color");
        const pointStyle = this.condition("point_style");
        const legendFontColor = this.condition("legend_font_color");
        const labelFontColor = this.condition("label_font_color");
        const angleLineColor = this.condition("angle_line_color");
        const accent_color = Config.settings.app.accent_color;
        const color = accent_color.substring(1, accent_color.length);
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        const len = this.props.data.datasets.length;
        let _maximum = 0;
        this.props.data.datasets.map((_item, i) => {
            const opacity = (i + 1) / len;
            _item.backgroundColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
            _item.borderColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

            _item.data.forEach((_val) => {
                if (_val > _maximum) {
                    _maximum = _val;
                }
            });
            return _item;
        });
        const { data } = this.props;
        return (
            <Radar
                height={this.props.height}
                data={data}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        tooltips: {
                            mode: "index",
                            backgroundColor: "#1B1B29",
                            titleAlign: "center",
                            titleFontSize: 14,
                            xPadding: 10,
                            titleFontFamily: "Quicksand",
                            titleMarginBottom: 10,
                            bodyFontFamily: "Lato",
                            bodyFontSize: 10,
                            position: "nearest",
                            yPadding: 10,
                            intersect: false, // Enable if you want labels to show when over a point
                            bodySpacing: 10,
                            displayColors: false,
                            callbacks: {
                                title: function (t, a) {
                                    return a.labels[t[0].index];
                                },
                                label: function (t, d) {
                                    return `${d.datasets[t.datasetIndex].label}: ${t.yLabel.toLocaleString("en-US", {
                                        maximumFractionDigits: 2
                                    })}`;
                                }
                            }
                        },
                        legend: {
                            display: showLegend,
                            labels: {
                                color: legendFontColor
                            }
                        }
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 5,
                            bottom: 5
                        }
                    },
                    datasets: {
                        radar: {
                            fill: false,
                            pointBackgroundColor: "#222235",
                            lineTension: lineTension,
                            borderWidth: borderWidth,
                            pointBorderWidth: pointBorderWidth,
                            pointRadius: pointRadius,
                            pointHoverBorderWidth: pointHoverBorderWidth,
                            pointHoverRadius: pointHoverRadius,
                            pointStyle: pointStyle
                        }
                    },
                    scales: {
                        r: {
                            angleLines: {
                                color: angleLineColor,
                                display: showAngleLines
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: _maximum / 5,
                                display: false
                            },
                            grid: {
                                display: showGridLines,
                                color: gridLineColor,
                                zeroLineColor: gridLineColor
                            },
                            pointLabels: {
                                display: showLabels,
                                color: labelFontColor
                            }
                        }
                    }
                }}
            />
        );
    }
}
RadarChart.contextType = AppContext;
RadarChart.propTypes = {
    height: PropTypes.number,
    data: PropTypes.object,
    labels: PropTypes.array,
    show_grid_lines: PropTypes.bool,
    show_labels: PropTypes.bool,
    show_legend: PropTypes.bool,
    component: PropTypes.string,
    border_width: PropTypes.number,
    point_border_width: PropTypes.number,
    point_radius: PropTypes.number,
    point_hover_radius: PropTypes.number,
    point_hover_border_width: PropTypes.number,
    line_tension: PropTypes.number
};
export default RadarChart;
