import React, { Component } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

class Feature extends Component {
    render() {
        const { alt, image, children } = this.props;
        return (
            <div className={alt === "true" ? "feature alt" : "feature"} {...this.props}>
                <Container fluid className="h-100">
                    <Row className="h-100 align-items-center text-md-left">
                        {image ? (
                            <Col xs={{ span: 12, order: 1 }} md={{ offset: 1, span: 6, order: alt === "false" ? 0 : 1 }} lg={{ offset: 1, span: 5, order: alt === "false" ? 0 : 1 }} className="d-none d-md-block">
                                <Image src={image} />
                            </Col>
                        ) : null}
                        <Col md={{ offset: !image ? 0 : 1, span: !image ? 12 : 4 }} lg={{ offset: !image ? 0 : 1, span: !image ? 12 : 4 }}>
                            {children}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Feature;
