import { mdiFinance } from "@mdi/js";
import React, { Component } from "react";
import Loading from "../../components/Loading";
import { withRouter } from "../../components/withRouter";
import { AppContext } from "../../contexts/AppContext";
import Players from "./components/Players";

class ReportView extends Component {
    constructor(props, context) {
        super(props);
        this.state = {
            players: [],
            report: []
        };
        context.PageTitle.set("Loading Report...");
    }

    componentDidMount() {
        const { API, PageTitle } = this.context;
        const { params } = this.props.router;
        API.get(`/reports/${params.id}`)
            .then((res) => {
                const { data } = res;
                PageTitle.set(data.map.name, mdiFinance);
                let _players = data.players.sort((a, b) => {
                    return a.summary.dps.outgoing.all >= b.summary.dps.outgoing.all ? -1 : 1;
                });
                this.setState({
                    players: _players,
                    report: data
                });
            })
            .catch((res) => {
                const { status, data } = res.response;
                switch (status) {
                    default: {
                        // Unknown Error
                        console.log(data);
                        break;
                    }
                }
            });
    }

    render() {
        const { players, report } = this.state;
        return (
            <section id="report" className="h-100">
                <div className="content-wrapper">{players.length === 0 ? <Loading /> : <Players players={players} report={report} />}</div>
            </section>
        );
    }
}
ReportView.contextType = AppContext;
ReportView.propTypes = {};

export default withRouter(ReportView);
