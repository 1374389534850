import React, { Component } from "react";

class Heading extends Component {
    render() {
        let words = this.props.children.split(" ");
        const elements = words.map((_word, _index) => {
            if (_index === 0) {
                return <span key={_index}>{_word}</span>;
            }
            return " " + _word;
        });
        switch (this.props.as) {
            case "h2":
                return <h2 {...this.props}>{elements}</h2>;
            case "h3":
                return <h3 {...this.props}>{elements}</h3>;
            case "h4":
                return <h4 {...this.props}>{elements}</h4>;
            case "h5":
                return <h5 {...this.props}>{elements}</h5>;
            default:
                return <h1 {...this.props}>{elements}</h1>;
        }
    }
}

export default Heading;
