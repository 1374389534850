import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import { AppContext } from "../../contexts/AppContext";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
class LineChart extends Component {
    constructor(props) {
        console.log("LineChart::constructor()");
        super(props);
    }

    condition = (_key) => {
        const { Config } = this.context;
        const { settings, component } = this.props;
        if (settings) {
            return settings[component][_key];
        }
        const _config = Config.settings.charts[component][_key];
        return _config;
    };

    componentDidMount() {}
    render() {
        const { data, height } = this.props;
        const { Config } = this.context;

        const showLabels = this.condition("show_labels");
        const showLegend = this.condition("show_legend");
        const showGridLines = this.condition("show_grid_lines");
        const lineTension = this.condition("line_tension");
        const borderWidth = this.condition("border_width");
        const pointBorderWidth = this.condition("point_border_width");
        const pointBorderColor = this.condition("point_border_color");
        const pointRadius = this.condition("point_radius");
        const pointHoverBorderWidth = this.condition("point_hover_border_width");
        const pointHoverRadius = this.condition("point_hover_radius");
        const pointStyle = this.condition("point_style");
        const legendFontColor = this.condition("legend_font_color");
        const labelFontColor = this.condition("label_font_color");
        const gridLineColor = this.condition("grid_line_color");
        const tickColor = this.condition("tick_color");
        const tickWidth = this.condition("tick_width");
        const tickLength = this.condition("tick_length");

        const accentColor = Config.settings.app.accent_color;

        // Configure Bar Color Gradients
        const color = accentColor.substring(1, accentColor.length);
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        const len = data.datasets.length;
        let _min = 10000000000;
        let _max = 0;
        data.datasets.map((_item, i) => {
            const opacity = (len - i) / len;
            _item.backgroundColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
            _item.borderColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
            _item.data.forEach((_val) => {
                if (_val > _max) {
                    _max = _val;
                } else {
                    if (_val < _min) {
                        _min = _val;
                    }
                }
            });
            return _item;
        });
        const offset1 = pointRadius - 10;
        const offset2 = pointBorderWidth;
        const offset3 = pointRadius + 10;
        const offset4 = 10 - pointBorderWidth;
        return (
            <Line
                height={height !== undefined ? height : 300}
                data={data}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltips: {
                            mode: "index",
                            backgroundColor: "rgba(27,27,41,0.90)",
                            titleAlign: "center",
                            titleFontSize: 14,
                            xPadding: 10,
                            titleFontFamily: "Quicksand",
                            titleMarginBottom: 10,
                            bodyFontFamily: "Quicksand",
                            bodyFontSize: 12,
                            yPadding: 10,
                            bodySpacing: 10,
                            displayColors: false,
                            callbacks: {
                                label: function (t, d) {
                                    return `${d.datasets[t.datasetIndex].label}: ${t.yLabel.toLocaleString("en-US", {
                                        maximumFractionDigits: 2
                                    })}`;
                                }
                            }
                        },
                        legend: {
                            display: showLegend,
                            labels: {
                                usePointStyle: true,
                                fontFamily: "Quicksand",
                                fontStyle: "bold",
                                color: legendFontColor
                            }
                        }
                    },
                    layout: {
                        padding: {
                            left: offset1 + offset2,
                            right: offset3 - offset4,
                            top: offset3 - offset4,
                            bottom: offset1 + offset2
                        }
                    },
                    datasets: {
                        line: {
                            fill: false,
                            pointStyle: pointStyle,
                            pointBackgroundColor: "#222235",
                            lineTension: lineTension,
                            borderWidth: borderWidth,
                            pointBorderWidth: pointBorderWidth,
                            pointBorderColor: pointBorderColor,
                            pointRadius: pointRadius,
                            pointHoverBorderWidth: pointHoverBorderWidth,
                            pointHoverRadius: pointHoverRadius
                        }
                    },
                    scales: {
                        y: {
                            border: {
                                display: false
                            },
                            min: _min - 1,
                            max: _max + 0.2,
                            type: "logarithmic",
                            ticks: {
                                display: false,
                                color: labelFontColor
                            },
                            grid: {
                                display: false,
                                drawBorder: false
                            }
                        },
                        x: {
                            border: {
                                display: false
                            },
                            ticks: {
                                display: showLabels,
                                fontFamily: "Quicksand",
                                color: labelFontColor
                            },
                            grid: {
                                display: showGridLines,
                                color: gridLineColor,
                                zeroLineColor: gridLineColor,
                                tickColor: tickColor,
                                tickWidth: tickWidth,
                                tickLength: tickLength
                            }
                        }
                    }
                }}
            />
        );
    }
}
LineChart.contextType = AppContext;

LineChart.propTypes = {
    height: PropTypes.number,
    data: PropTypes.object,
    labels: PropTypes.array,
    show_grid_lines: PropTypes.bool,
    show_labels: PropTypes.bool,
    show_legend: PropTypes.bool,
    component: PropTypes.string,
    border_width: PropTypes.number,
    point_border_width: PropTypes.number,
    point_radius: PropTypes.number,
    point_hover_radius: PropTypes.number,
    point_hover_border_width: PropTypes.number,
    line_tension: PropTypes.number
};

export default LineChart;
