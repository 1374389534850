import { Doughnut } from "react-chartjs-2";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart, registerables } from "chart.js";
import { AppContext } from "../../contexts/AppContext";
Chart.register(...registerables);
class DonutChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasets: [],
            colors: [],
            options: {}
        };
        this.condition = this.condition.bind(this);
    }
    componentDidMount() {
        console.log("DonutChart::componentDidMount()");
    }
    condition(_key) {
        const { Config } = this.context;
        const { settings, component } = this.props;
        if (settings) {
            return settings[component][_key];
        }
        if (!component) {
            return false;
        }
        console.log(Config.settings.charts[component][_key]);
        const _config = Config.settings.charts[component][_key];
        return _config;
    }
    render() {
        const { Config } = this.context;
        const { data } = this.props;
        const showLegend = this.condition("show_legend");
        const labelFontColor = this.condition("label_font_color");
        const color = Config.settings.app.accent_color.substring(1, Config.settings.app.accent_color.length);
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        data.datasets.forEach((_item) => {
            const len = _item.data.length;
            _item.backgroundColor = [];
            for (let i = len; i >= 0; i--) {
                const opacity = i / len;
                _item.backgroundColor.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
            }
        });
        const options = {
            aspectRatio: 1,
            cutout: "75%",
            maintainAspectRatio: this.props.maintainAspectRatio || false,
            responsive: true,
            tooltips: {
                backgroundColor: "#1B1B29",
                titleAlign: "center",
                titleFontSize: 14,
                xPadding: 10,
                titleFontFamily: "Quicksand",
                titleMarginBottom: 10,
                bodyFontFamily: "Lato",
                bodyFontSize: 10,
                yPadding: 10,
                intersect: false, // Enable if you want labels to show when over a point
                bodySpacing: 10,
                displayColors: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        try {
                            let label = data.labels[tooltipItem.index] || "";

                            if (label) {
                                label += ": ";
                            }

                            const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                                return accumulator + curValue;
                            });
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                            label += Number((value / sum) * 100).toFixed(0) + "%";
                            return label;
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            datasets: {
                doughnut: {
                    borderWidth: 0,
                    fill: false
                }
            },
            plugins: {
                legend: {
                    display: showLegend,
                    labels: {
                        color: labelFontColor
                    }
                }
            }
        };
        return <Doughnut width={this.props.width} height={this.props.height} data={data} options={options} />;
    }
}
DonutChart.contextType = AppContext;
DonutChart.propTypes = {
    maintainAspectRatio: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    data: PropTypes.object
};

export default DonutChart;
