import { mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Component } from "react";
import DonutChart from "./Charts/DonutChart";
import ToolTip from "./ToolTip";
class ChartCard extends Component {
    render() {
        const { icon, value, data, body, header, tooltip } = this.props;
        return (
            <div className="chart-card">
                <div className="chart">
                    {tooltip ? (
                        <div className="info">
                            <ToolTip text={tooltip}>
                                <Icon path={mdiInformation} size={1} />
                            </ToolTip>
                        </div>
                    ) : null}
                    <div className="icon">{icon ? <Icon path={icon} size={1.25} /> : value}</div>
                    <DonutChart width={48} height={48} data={data} />
                </div>
                <div className="content">
                    <div className="header">{header}</div>
                    <div className="body">{body}</div>
                </div>
            </div>
        );
    }
}
export default ChartCard;
