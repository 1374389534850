class ValidationHelper {
    isEmail(_str) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)

        return this.isLength(_str, { min: 6 }) && pattern.test(_str)
    }
    isAlphaNumeric(_str) {
        return _str.match(/^[a-z0-9 '-.]+$/i)
    }
    isMatch(_a, _b) {
        return _a === _b
    }
    isLength(_str, _opts) {
        const len = _str.length
        if (_opts.max === undefined) {
            return len >= _opts.min
        } else if (_opts.min === undefined) {
            return len <= _opts.max
        } else if (_opts.min !== undefined && _opts.max !== undefined) {
            return len >= _opts.min && len <= _opts.max
        }
    }
}

export default new ValidationHelper()
