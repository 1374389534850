import React, { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <footer>
                <p>Copyright &copy; STO-Nexus.com {new Date().getFullYear()}. All Rights Reserved.</p>
                <small>We are in no way affiliated with the DPS League, DPS Channels, CLR, SCM, ACT, Cryptic Studios or Perfect World Entertainment</small>
            </footer>
        );
    }
}

export default Footer;
