import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ChartSwitch from "../../../components/ChartSwitch";
import DataTable from "../../../components/DataTable";
import { AppContext } from "../../../contexts/AppContext";

const Attacks = (props) => {
    const { Config } = useContext(AppContext);
    const [weapons, setWeapons] = useState([]);
    const [flags, setFlags] = useState([]);
    const [chart, setChart] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        let _chart = {
            labels: [],
            datasets: [
                {
                    label: "DPS",
                    data: []
                },
                {
                    label: "Damage",
                    data: []
                },
                {
                    label: "Duration",
                    data: []
                }
            ]
        };
        let _weapons = [];
        let _flags = [];
        props.weapons.forEach((_weapon) => {
            _chart.labels.push(_weapon.name);
            _chart.datasets[0].data.push(_weapon.dps.all);
            _chart.datasets[1].data.push(_weapon.damage.all);
            _chart.datasets[2].data.push(_weapon.duration.all);

            let weapon = {
                name: _weapon.name,
                buff: _weapon.buff ? _weapon.buff : "--",
                dps: _weapon.dps.all,
                damage_all: _weapon.damage.all,
                damage_shield: _weapon.damage.shield,
                damage_hull: _weapon.damage.hull,
                shots_all: _weapon.shots.all,
                shots_shield: _weapon.shots.shield,
                shots_hull: _weapon.shots.hull,
                accuracy: _weapon.accuracy,
                crit_chance: _weapon.crit_chance,
                duration: _weapon.duration.all,
                flags: {}
            };
            if (_weapon.procs) {
                Object.keys(_weapon.procs).forEach((_flag) => {
                    weapon[_flag] = _weapon.procs[_flag];
                    if (!_flags.includes(_flag)) {
                        _flags.push(_flag);
                    }
                });
            }
            _weapons.push(weapon);
        });

        setChart(_chart);
        setFlags(_flags);
        setWeapons(_weapons);
    }, [props.weapons, Config]);

    const headers = [
        { key: "name", text: "Name" },
        { key: "buff", text: "Buff" },
        { key: "dps", text: "DPS", show: Config.settings.tables.report.show_dps },
        { key: "damage_all", text: "Damage", show: Config.settings.tables.report.show_damage_all },
        { key: "damage_shield", text: "Damage (Shield)", show: Config.settings.tables.report.show_damage_shield },
        { key: "damage_hull", text: "Damage (Hull)", show: Config.settings.tables.report.show_damage_hull },
        { key: "shots_all", text: "Hits", show: Config.settings.tables.report.show_hits_all },
        { key: "shots_shield", text: "Hits (Shield)", show: Config.settings.tables.report.show_hits_shield },
        { key: "shots_hull", text: "Hits (Hull)", show: Config.settings.tables.report.show_hits_hull },
        { key: "crit_chance", text: "Crit %", show: Config.settings.tables.report.show_crit_chance },
        { key: "accuracy", text: "Acc.", show: Config.settings.tables.report.show_accuracy }
    ];
    flags.forEach((_flag) => {
        let label = _flag.replaceAll("_", " ");
        if (label.substring(label.length - 1, label.length) === "s") {
            label += "es";
        } else {
            label += "s";
        }
        label = label.charAt(0).toUpperCase() + label.slice(1);
        if (label.indexOf(" ") > -1) {
            label = label.substring(0, label.indexOf(" ") + 1) + label.charAt(label.indexOf(" ") + 1).toUpperCase() + label.slice(label.indexOf(" ") + 2);
        }
        headers.push({ key: _flag, text: label, show: Config.settings.tables.report[`show_${_flag}`] });
    });
    headers.push({ key: "duration", text: "Duration", show: Config.settings.tables.report.show_duration });
    return (
        <>
            <Card>
                <Card.Header>{props.title}</Card.Header>
                <Card.Body style={{ height: 320 }}>
                    <ChartSwitch component="detailed" labels={chart.labels} data={chart.datasets} />
                </Card.Body>
            </Card>
            <Card>{weapons.length > 0 ? <DataTable defaultKey="dps" limit={10} limits={[10, 25, 50, 100]} thead={headers} tbody={weapons} /> : null}</Card>
        </>
    );
};
export default Attacks;
